import React from "react";
import StyledSearchIcon from "./components/SearchIcon.styled";
import StyledSearchInput from "./components/SearchInput.styled";
import StyledSearchInputContainer from "./components/SearchInputContainer.styled";

interface SearchInputProps {
  searchValue?: string;
  onSearch?: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  searchValue,
  onSearch,
}) => {
  return (
    <StyledSearchInputContainer>
      {!searchValue && <StyledSearchIcon />}
      <StyledSearchInput
        type="text"
        placeholder="Search Templates"
        value={searchValue}
        onChange={e => onSearch(e.target.value)}
        style={{
          width: searchValue
            ? searchValue.length * 19 > 100
              ? searchValue.length * 20 + 4
              : 100
            : 300,
        }}
      />
    </StyledSearchInputContainer>
  );
};

export default SearchInput;
