import React from "react";
import StyledComponentsResults from "./ComponentsResults.styled";
import ComponentCategory from "@components/ComponentCategory";

interface ComponentsResultsProps {
  results: {
    key: string;
    name: string;
    Icon: any;
  }[];
}

const ComponentsResults: React.FC<ComponentsResultsProps> = ({
  results,
}) => {
  return (
    <StyledComponentsResults>
      {results.length
        ? results.map(({ Icon, key, name }) => (
            <ComponentCategory
              key={key}
              categoryName={key}
              name={name}
              Icon={Icon}
            />
          ))
        : "No components found"}
    </StyledComponentsResults>
  );
};

export default ComponentsResults;
