import {
  FaWpforms,
  FaRegSadTear,
  FaUserPlus,
  FaRegImages,
  FaSlidersH,
  FaRegCalendarAlt,
  FaListUl,
  FaUserFriends,
  FaQuoteLeft,
} from "react-icons/fa";
import {
  FiNavigation2,
  FiLayout,
  FiUser,
  FiSidebar,
} from "react-icons/fi";
import {
  AiOutlineNotification,
  AiOutlineTeam,
  AiOutlineLayout,
} from "react-icons/ai";
import {
  IoMdPricetags,
  IoMdContact,
  IoMdSettings,
} from "react-icons/io";
import { TiTabsOutline } from "react-icons/ti";

export default [
  {
    key: "login",
    name: "Login",
    Icon: FaWpforms,
  },
  {
    key: "register",
    name: "Register",
    Icon: FaUserPlus,
  },
  {
    key: "navbar",
    name: "Navbar",
    Icon: FiNavigation2,
  },
  {
    key: "about",
    name: "About",
    Icon: AiOutlineTeam,
  },
  {
    key: "gallery",
    name: "Gallery",
    Icon: FaRegImages,
  },
  {
    key: "modal",
    name: "Modal",
    Icon: AiOutlineNotification,
  },
  {
    key: "testimonials",
    name: "Testimonials",
    Icon: FaQuoteLeft,
  },
  {
    key: "form",
    name: "Form",
    Icon: FaWpforms,
  },
  {
    key: "pricing",
    name: "Pricing",
    Icon: IoMdPricetags,
  },
  {
    key: "contact",
    name: "Contact",
    Icon: IoMdContact,
  },
  {
    key: "careers",
    name: "Careers",
    Icon: FaUserFriends,
  },
  {
    key: "settings",
    name: "Settings",
    Icon: IoMdSettings,
  },
  {
    key: "sidebar",
    name: "Sidebar",
    Icon: FiSidebar,
  },
  {
    key: "profile",
    name: "User Profile",
    Icon: FiUser,
  },
  {
    key: "calendar",
    name: "Calendar",
    Icon: FaRegCalendarAlt,
  },
  {
    key: "footer",
    name: "Footer",
    Icon: AiOutlineLayout,
  },
  {
    key: "tabs",
    name: "Tabs",
    Icon: TiTabsOutline,
  },
  {
    key: "list",
    name: "List",
    Icon: FaListUl,
  },
  {
    key: "layouts",
    name: "Layouts",
    Icon: FiLayout,
  },
  {
    key: "popups",
    name: "Popups",
    Icon: AiOutlineNotification,
  },
  {
    key: "sliders",
    name: "Sliders",
    Icon: FaSlidersH,
  },

  {
    key: "404",
    name: "404",
    Icon: FaRegSadTear,
  },
];
