import styled from "styled-components";
import { Link } from "gatsby";
import CategoryText from "./components/CategoryText.styled";

const ComponentCategory = styled(Link)`
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.9;
    ${CategoryText} {
      color: #1a7ece;
    }
  }
`;

export default ComponentCategory;
