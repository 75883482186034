import styled from "styled-components";
import media from "@styles/media";

const CategoryWrapper = styled.div`
  height: 250px;
  width: 250px;
  border: 1px solid black;
  box-shadow: 0 1px 2px 0 rgba(25, 25, 25, 0.1);
  border: 1px solid #e9e9e9;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.6);
  ${media.toPhone`
    width: 100%;
  `}
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem;
  }
`;

export default CategoryWrapper;
