import React from "react";
import CategoryText from "./components/CategoryText.styled";
import ComponentCategoryLink from "./ComponentCategory.styled";
import StyledWrapper from "./components/Wrapper.styled";

interface ComponentCategoryProps {
  name?: string;
  categoryName?: string;
  Icon?: any;
}

const ComponentCategory: React.FC<ComponentCategoryProps> = ({
  Icon,
  name,
  categoryName,
}) => {
  return (
    <ComponentCategoryLink to={`/components/${categoryName}`}>
      <StyledWrapper>
        <Icon />
        <CategoryText>{name}</CategoryText>
      </StyledWrapper>
    </ComponentCategoryLink>
  );
};

export default ComponentCategory;
