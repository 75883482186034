import styled from "styled-components";

const SearchInput = styled.input`
  border: none;
  font-size: 2rem;
  outline: none;
  margin-left: 10px;
  &::-webkit-input-placeholder {
    font-weight: 100;
  }
`;

export default SearchInput;
