import styled from "styled-components";

const SearchInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 100px;
  width: 100%;
  border-bottom: 1px solid #d8d8d9;
  padding-top: 80px;
  width: 100%;
  input {
    text-indent: 2;
  }
`;

export default SearchInputContainer;
