import React from "react";
import { PageProps } from "gatsby";

import ComponentsList from "@views/ComponentsList";
import Layout from "../ui/layout";
import Banner from "@components/Banner/Banner";

const IndexPage: React.FC<PageProps> = props => {
  return (
    <Layout
      header={{ color: "black" }}
      seo={{
        title:
          "Components Design For Inspiration (with React.js implementation)",
        description:
          "A variety of react components that will help boost your inspiration. Find creative designs that are implemented in react and include them now in your project for free!",
      }}
    >
      <ComponentsList />
    </Layout>
  );
};

export default IndexPage;
