import React, { useState, useEffect } from "react";
import StyledCommentsList from "./ComponentsList.styled";
import Container from "@components/Container";
import SearchInput from "@views/TemplatesList/containers/Search/components/SearchInput";
import ComponentsResults from "./components/ComponentsResults";
import componentTypes from "@data/component-types";

interface ComponentsListProps {}

const ComponentsList: React.FC<ComponentsListProps> = ({}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [results, setResults] = useState(componentTypes);

  useEffect(() => {
    const newComponentTypes = componentTypes.filter(
      (item: any) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        searchValue.toLowerCase().includes(item.name.toLowerCase()),
    );
    setResults(newComponentTypes);
  }, [searchValue]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  return (
    <StyledCommentsList>
      <SearchInput
        searchValue={searchValue}
        onSearch={value => handleSearch(value)}
      />
      <Container>
        <ComponentsResults results={results} />
      </Container>
    </StyledCommentsList>
  );
};

export default ComponentsList;
