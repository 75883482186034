import styled from "styled-components";

const ComponentsResults = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: center;
  grid-gap: 50px;
  margin: 40px 0;
`;

export default ComponentsResults;
